import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import ItemPage from './components/item/ItemPage';
import { useHistory } from 'react-router-dom';

class StackViewStore {
  stacks: { id: number; prevUrl: string; prevTitle: string }[] = [];

  addStack(id: number) {
    const stack = {
      id,
      prevUrl: `${window.location.pathname}${window.location.search}`,
      prevTitle: document.title
    };
    this.stacks.push(stack);
    // return () => (this.stacks = this.stacks.filter(s => s! === stack));

    // this.history?.push(`/item?id=${id}`);
    window.history.pushState(
      { urlPath: `/item?id=${id}` },
      '',
      `/item?id=${id}`
    );

    if (this.stacks.length > 5) {
      alert(`You are at ${this.stacks.length} stacks !`);
    }
  }

  back(updateHistory = true) {
    const last = this.stacks.pop();

    console.log('back', '>>>', last?.prevUrl, '<<<<');

    if (updateHistory) {
      // window.history.back();
      // window.history.replaceState(
      window.history.pushState({ urlPath: last?.prevUrl }, '', last?.prevUrl);
      if (last) document.title = last?.prevTitle;
    }
  }

  get show() {
    return this.stacks.length > 0;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const stackViewHelper = new StackViewStore();
// const bodyPosition = document.body.style.position;
const bodyOverflow = document.body.style.overflow;

export const StackView = observer(() => {
  useEffect(() => {
    console.log(' > ', document.body.style.overflow);

    const reset = () => {
      // document.body.style.position = bodyPosition;
      document.body.style.overflow = bodyOverflow;
      document.body.style.paddingRight = `0`;
    };

    if (stackViewHelper.show) {
      // document.body.style.position = 'fixed';
      // padding-left: calc(100vw - 100%);

      // need to also do this for every stack div underneath us
      document.body.style.paddingRight = `${
        window.innerWidth - document.body.clientWidth
      }px`;
      document.body.style.overflow = 'hidden';
    } else {
      reset();
    }

    console.log(' > ', document.body.style.overflow);

    return () => {
      reset();
    };
  }, [stackViewHelper.show]);

  const history = useHistory();

  useEffect(() => {
    history.listen(location => {
      if (history.action === 'POP') {
        console.log('>>>> HI');

        if (stackViewHelper.show) stackViewHelper.back(false);
      }
    });
  }, [history]);

  useEffect(() => {
    const keydown = ({ key }: { key: string }) => {
      if (key === 'Escape') {
        stackViewHelper.back();
      }
    };

    document.addEventListener('keydown', keydown);

    return () => document.removeEventListener('keydown', keydown);
  }, []);

  return (
    <>
      {stackViewHelper.stacks.map(s => (
        <div
          className="stack"
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1000,

            background: 'rgba(0,0,0,0.8)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

            overscrollBehavior: 'contain',
            overflow: 'auto'
          }}
        >
          <div
            className="item-page"
            style={{
              maxWidth: '800px',
              width: '100%',
              minHeight: '100%'
            }}
          >
            <ItemPage id={s.id} key={s.id} />
          </div>
        </div>
      ))}
    </>
  );
});
