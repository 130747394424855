import React, { FC } from 'react';
import Icon from '../Icon';
import { stackViewHelper } from '../../StackView';

type LinkUrlCardProps = {
  url: string;
  className?: string;
  internal?: boolean;
};

const LinkUrlCard: FC<LinkUrlCardProps> = ({
  url,
  className,
  internal = false
}) => (
  <a
    onClick={e => {
      if (internal) {
        e.preventDefault();
        stackViewHelper.addStack(parseInt(url.split('=')[1]));
      }
    }}
    style={{ cursor: 'pointer' }}
    href={url}
    className={`p-1 link-card d-flex align-items-center ${className ?? ''}`}
  >
    <Icon size={2} name="compass" />
    <span
      className="pl-2 ml-2 pr-2 link-card--text text-truncate"
      style={{ flex: '1' }}
    >
      {url}
    </span>
  </a>
);

export default LinkUrlCard;
