import React, { RefObject, createContext } from 'react';
import { Link } from 'react-router-dom';
import { topOfElIsVisible } from './helpers';
import { Item } from './Item';
import { FloatingButton } from '../FloatingButton';
import { stackViewHelper } from '../../StackView';
import { observer } from 'mobx-react-lite';

type OpContainerI = {
  originalPoster?: string;
  setOriginalPoster: (op?: string) => void;
};

export const CommentRefContext = createContext<{
  addTopLevelCommentRef: (ref: RefObject<HTMLElement>) => void;
  opContainer: OpContainerI;
}>({
  addTopLevelCommentRef: () => {},
  opContainer: {
    originalPoster: undefined,
    setOriginalPoster: () => {}
  }
});

const ItemPage = observer(({ id }: { id: number }) => {
  const topLevelCommentRefs: RefObject<HTMLElement>[] = [];
  const addTopLevelCommentRef = (ref: RefObject<HTMLElement>) =>
    topLevelCommentRefs.push(ref);

  const goToNextComment = () => {
    const firstCommentWithTopVisible = topLevelCommentRefs.filter(
      e => !topOfElIsVisible(e, stackViewHelper.show ? 50 : 5)
    )[0];

    if (firstCommentWithTopVisible?.current) {
      const nodes = document.querySelectorAll('.stack') ?? [];
      const stack = nodes[nodes.length - 1];

      if (stack) {
        stack.scrollTo({
          top: firstCommentWithTopVisible.current.offsetTop - 44,
          behavior: 'smooth'
        });
      } else {
        window.scrollTo({
          top: firstCommentWithTopVisible.current.offsetTop,
          behavior: 'smooth'
        });
      }
    }
  };

  const opContainer: OpContainerI = {
    originalPoster: undefined,
    setOriginalPoster: (op?: string) => (opContainer.originalPoster = op)
  };

  return (
    <div className="item-page">
      {stackViewHelper.show ? (
        <div
          onClick={() => {
            stackViewHelper.back();
          }}
          style={{
            cursor: 'pointer',
            position: 'sticky',
            top: 0,
            padding: '5px',
            display: 'flex',
            justifyContent: 'end'
          }}
          className="pl-2 header-sticky"
        >
          <button style={{ border: 'none', backgroundColor: 'transparent' }}>
            X
          </button>
        </div>
      ) : (
        <Link to="/" className="pl-2">
          &laquo; home
        </Link>
      )}

      {/* Verify render side effects https://reactjs.org/docs/context.html#caveats */}
      <CommentRefContext.Provider
        value={{ addTopLevelCommentRef, opContainer }}
      >
        <Item id={id} />
      </CommentRefContext.Provider>
      <FloatingButton onClick={goToNextComment} className="d-md-none">
        &darr;
      </FloatingButton>
      <div className="d-md-none" style={{ paddingBottom: '80px' }}></div>
    </div>
  );
});

export default ItemPage;
